import React, { useState } from 'react';
import * as APIUtils from '../../api/APIUtils.js';
import Submit from '../buttons/Submit.js';
import { Button, Col, Container, FloatingLabel, Form, Row, Stack } from 'react-bootstrap';
import Copy from '../buttons/Copy.js';

export default function ToJSON({addSuccess, addWarning, addError, clearAlerts}) {
    const [processing, setProcessing] = useState(false);
    const [xml, setXml] = useState('');
    const [output, setOutput] = useState('');

    const handleXmlChange = event => {
        setXml(event.target.value);
    }

    const handleOutputChange = event => {
        setOutput(event.target.value);
    }

    const handleReset = () => {
        setXml('');
        setOutput('');
        clearAlerts();
    }

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
        clearAlerts();
        setProcessing(true);
        const path = '/api/xml/json';
        const data = xml;
        APIUtils.postJSON(path, data).then(({status, json}) => {
            setProcessing(false);
            switch(status) {
                case 200: 
                    setOutput(json);
                    break;
                case 400:
                    addWarning(json.message);
                    break;
                case 500:
                    addError(json.message);
                    break;
                default:
                    addError(json);
            }
        });
    }

    return (
        <div>
            <Container fluid='true'>
                <Row xs={1} md={2}>
                    <Col>
                        <Form onSubmit={handleSubmit}>
                            <FloatingLabel
                                controlId="floatingTextarea"
                                label="XML"
                                className="mb-3"
                            >
                                <Form.Control 
                                    as="textarea" 
                                    placeholder='XML'
                                    value={xml} 
                                    onChange={handleXmlChange}
                                    style={{ height: '350px' }}
                                    required
                                />
                            </FloatingLabel>
                            <Stack direction="horizontal" gap={2} className="mb-3">
                                <Submit buttonText={'Convert'} processing={processing}/>
                                <Button variant="outline-secondary" onClick={handleReset}>Reset</Button>
                            </Stack>
                        </Form>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="floatingTextarea"
                            label="JSON"
                            className="mb-3"
                        >
                            <Form.Control 
                                as="textarea" 
                                placeholder='JSON'
                                value={output} 
                                onChange={handleOutputChange}
                                style={{ height: '350px' }}
                            />
                        </FloatingLabel>
                        <Copy content={output}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}